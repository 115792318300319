import React from 'react';
import Login from './Login';

const LoginPage = () => (
  <div>
    <Login />
  </div>
);

export default LoginPage;
